<!--  -->
<template>
  <div class="main-area border-box">
    <div class="auto clearfix">
      <div class="common-questions sub1-common-questions">
        <div class="crumbs-nav clearfix">
          <p>
            <router-link to="/help">
              <a class="menu-lv1 return-help"></a>
            </router-link>
            <a class="menu-lv2">&nbsp;搜索“{{ this.$route.query.name }}&nbsp;</a
            >”&nbsp;
          </p>
        </div>
        <div class="q-block clearfix" style="padding-top: 0">
          <div class="nav-tab-wrapper clearfix">
            <a
              class="nav-tab-item"
              @click="change(item, index)"
              :class="{ active: isActive == index }"
              v-for="(item, index) in list"
              :key="index"
              >{{ item }}</a
            >
          </div>
          <div class="tab-content">
            <ul class="q-list"></ul>
          </div>
          <div class="page-btn">
            <table cellspacing="0" cellpadding="0"></table>
          </div>
        </div>
      </div>
      <div class="clear-float"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: 0,
      list: ["全部（0）", "帮助文档（0）", "新闻中心（0"],
    };
  },
  methods: {
    change(item, index) {
      this.isActive = index;
    },
  },
};
</script>

<style scoped></style>
