<!-- 帮助中心 -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="help-banner">
      <dl>
        <dt></dt>
        <dd>
          <form action="javascript:;" method="get">
            <input
              name="k"
              type="text"
              placeholder="请输入您要搜索的关键词..."
              value=""
            /><input type="submit" value="" />
          </form>
          <ul>
            <li>
              <router-link :to="{ name: 'search', query: { name: '百度推广' } }"
                ><a>百度推广</a></router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'search', query: { name: '商标注册' } }"
                ><a>商标注册</a></router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'search', query: { name: '天猫入驻' } }"
                ><a>天猫入驻</a></router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'search', query: { name: '腾讯云企业服务' } }"
                ><a>腾讯云企业服务</a></router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'search', query: { name: '短视频服务' } }"
                ><a>短视频服务</a></router-link
              >
            </li>
          </ul>
        </dd>
      </dl>
    </div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import Search from "../help/components/search/index.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    Search,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import "../../assets/static/template/Home/Zkeys/PC/Static/statics/css/front/help.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/help/help.css";
</style>
